<template lang='pug'>
  div
    .text-xl.font-bold Список заказов
    .text-sm.mb-4 Вы можете просмотреть и отредактировать каждый заказ

    template(v-if='parcels')
      p-table(
        :data='parcels'
        :search='true'
        :headers='{ "ownerString": "Кому посылка", "addressString": "Куда посылка", "status": "Текущий статус" }'
        :actions='{ view: showParcel }'
      )
    template(v-else)
      .text-sm.opacity-20.mt-5 Идёт загрузка данных...
</template>

<script>
import { getParcels } from '../assets/api.js';

export default {
  mounted() {
    this.loadData();
  }, 
  data() {
    return {
      parcels: null
    }
  },
  methods: {
    /**
     * Загрузить данные о складах из БД
     */
    async loadData() {
      const result = await getParcels();
      if (!result.success) {
        console.error('Ошибка получения списка полок');
        return;
      }

      result.data.forEach(value => {
        value.addressString = `${value.owner.info.addresses[0].country}, ${value.owner.info.addresses[0].address1}, ${value.owner.info.addresses[0].index}`;
        value.ownerString = `${value.owner.info.personal.first_name} ${value.owner.info.personal.last_name}`;
        value.ownerNumber = value.owner.info.personal.number;

        const lastStatus = value.history[0];
        if (lastStatus) {
          value.status = `${lastStatus.status} (${this.moment(lastStatus.date).format('DD.MM.YY HH:mm')})`;
        }
      });

      this.parcels = result.data;
    },

    /**
     * Показать полки
     */
    showParcel(parcel) {
      this.$router.push(`/parcel/${parcel._id}`);
    }
  }
}
</script>

<style>

</style>